import React from 'react'
import styled from 'styled-components'

import Layout from '../layouts'
import MetaHeader from '../components/MetaHeader'
import { colors, vars } from '../components/theme'
import PageWrapper from '../components/PageWrapper'
import { Row, Col } from '../components/Grid'
import { Checkbox, Input, Radio, Textarea } from '../components/common/form'
import Button from '../components/Button'

const Container = styled.div`
  margin: 4rem auto;
`
const FormContainer = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid ${colors.lightGrey};

  @media (min-width: ${vars.screenSmall}) {
    border: none;
  }
`

const FoundersRegistration = () => (
  <Layout>
    <MetaHeader title="Founders Registration" />

    <PageWrapper>
      <section>
        <Container>
          <Row>
            <Col minWidth>
              <h2 className="mt-0">
                Are you a Founder interested in joining Investor Roundtables?
              </h2>
              <p>
                We’re on a mission to increase diversity in early-stage
                investing around the world.
              </p>
              <p>
                Investor Roundtables give entrepreneurs the opportunity to
                showcase their businesses to curated groups of investors within
                the Female Funders network.
              </p>
              <p>
                If you would like to be considered for the Investor Roundtables,
                please complete the following form.
              </p>
              <p>We will reach out if your venture qualifies.</p>
              <p>
                Questions? Email{' '}
                <a href="mailto:hello@femalefunders.com">
                  hello@femalefunders.com
                </a>
                .
              </p>
              <br />
              <p>
                If you’re curious to learn more about Female Funders and how you
                can get involved as an entrepreneur, you are invited to join one
                of our monthly informational calls for founders.{' '}
                <a href="https://zoom.us/meeting/register/ed83bac2cc47810d7c24e00bf0acd2b8">
                  Please register here.
                </a>
              </p>
            </Col>
            <Col minWidth>
              <FormContainer>
                <form
                  className="form"
                  name="FoundersRegistration"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  action="/application-success">
                  <input
                    type="hidden"
                    name="form-name"
                    value="FoundersRegistration"
                  />
                  <Input name="firstName" label="First name" />
                  <Input name="lastName" label="Last name" />
                  <Input name="email" label="Email" type="email" />
                  <Input
                    name="linkedinLink"
                    label="LinkedIn profile"
                    optional
                  />
                  <Row smallGutter>
                    <Col>
                      <Input name="city" label="City" />
                    </Col>
                    <Col>
                      <Input name="country" label="Country" />
                    </Col>
                  </Row>
                  <Input name="role" label="Your role" />
                  <Input name="industry" label="Industry" />
                  <Input name="companyWebsite" label="Company website" />
                  <Textarea
                    name="companyDescription"
                    label="Describe your company"
                  />
                  <Radio
                    name="capital"
                    label="Are you currently raising capital?"
                    options={[
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' },
                    ]}
                  />
                  <Input
                    name="stage"
                    label="If so, what stage of funding?"
                    optional
                  />
                  <Textarea
                    name="community"
                    label="Describe what you hope to gain by connecting to the Female Funders community"
                  />
                  <p>
                    Do you have a mentor or lead investor who can act as a
                    reference for your company? Please provide their contact
                    information:
                  </p>
                  <Input
                    name="refFirst"
                    label="Reference's First Name"
                    optional
                  />
                  <Input
                    name="refLast"
                    label="Reference's Last Name"
                    optional
                  />
                  <Input name="refEmail" label="Reference's Email" optional />

                  <input type="hidden" value="false" name="newsletter" />
                  <Checkbox
                    name="newsletter"
                    label="Sign me up for the Female Funders newsletter"
                    options={[{ label: 'Yes please', value: 'true' }]}
                  />

                  <div data-netlify-recaptcha="true" />
                  <Button primary submit>
                    Submit
                  </Button>
                </form>
              </FormContainer>
            </Col>
          </Row>
        </Container>
      </section>
    </PageWrapper>
  </Layout>
)

export default FoundersRegistration
